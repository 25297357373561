import { Flex, Input, Table, Text, Tbody, Td, Th, Thead, Tr, chakra } from '@chakra-ui-kraud/react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormPreformModel } from '../../model/preform.types';
import { emptyRows, LINK_TO_TECH_CARD_INFO, useTechCard } from '@/shared';
import { FC, useEffect, useState } from 'react';
import { SGDPreformBlockTypes } from './SGD.types';
import { useFormValues } from '../../lib/use-form-values';
import { useGetEmployeeInitialsQuery, useGetPreformTechMapByNumberQuery } from '@/shared/state/api/swagger';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { selectEmployesForOptions } from '@/entities/employee';
import clsx from 'clsx';
import styles from './SGD.module.scss';
import { MKSelectPreform } from '@/features/mk-select/mk-select-preform';
import { Link } from 'react-router-dom';
import defaultStyles from '../../tech-card.module.scss';
import { useParams } from 'react-router-dom';
import { convertNumberToNumberStringWithComma } from '@/shared/core/utils/convert-string-to-number-string';

const COLUMNS_COUNT = 10;

export const SgdPreformBlock: FC<SGDPreformBlockTypes> = ({
	isEditable,
	isPrinting,
	setFocusedBlock,
	focusedBlock,
}) => {
	const { isCreationMode } = useTechCard();
	const { control, setValue } = useFormContext<FormPreformModel>();
	const { preform_sgd_out } = useFormValues<FormPreformModel>();

	const { options: employee } = useGetEmployeeInitialsQuery(undefined, {
		selectFromResult: (result) => ({ ...result, options: selectEmployesForOptions(result?.data) }),
	});

	const [rows, setRows] = useState(3);
	const mapNumber = Number(useParams().number);
	const { data: preformMap } = useGetPreformTechMapByNumberQuery(
		{ preformTechMapNumber: mapNumber },
		{
			skip: !mapNumber || Number.isNaN(mapNumber),
		},
	);

	useEffect(() => {
		if (isPrinting && preformMap?.preform_operations) {
			// Число недостоящих строк "Выдано из СГД" для заполнения до конца второй страницы при печати
			// 73 - число строк, которые помещаются на двух страницах после первого сегмента
			// 2 - число пустых строк после кастомных операций
			const rowsToPrint =
				73 - (preformMap?.preform_operations.length + 2 + (preformMap?.preform_sgd_out?.length ?? 0));
			setRows(rowsToPrint);
		} else {
			setRows(3);
		}
	}, [preformMap, isPrinting]);

	return (
		<Flex
			onFocus={() => setFocusedBlock && setFocusedBlock('SGDOut')}
			className={clsx(
				clsx(styles['sgd'], styles['sgd-preform']),
				focusedBlock?.block === 'SGDOut' &&
					focusedBlock.focusedBy === 'tab' &&
					!focusedBlock.atCurrentBlock &&
					defaultStyles.focused,
			)}
		>
			<Table>
				<Thead>
					<Tr>
						<Th colSpan={COLUMNS_COUNT}>Выдано со склада</Th>
					</Tr>
					<Tr>
						<Th style={{ verticalAlign: 'middle' }} w="120px">
							Дата выдачи
						</Th>
						<Th style={{ verticalAlign: 'middle' }} w="100px">
							№МК
						</Th>
						<Th style={{ verticalAlign: 'middle' }} maxW="200px">
							Кол-во выданного м X шт.
						</Th>
						<Th style={{ verticalAlign: 'middle' }} minW="120px" maxW="200px">
							Получатель
						</Th>
						<Th style={{ verticalAlign: 'middle' }} maxW="200px">
							ФИО выдавшего материал
						</Th>
						<Th style={{ verticalAlign: 'middle' }} w="120px">
							Подпись
						</Th>
						<Th style={{ verticalAlign: 'middle' }} w="120px">
							Дата возврата
						</Th>
						<Th style={{ verticalAlign: 'middle' }} maxW="200px">
							Кол-во вернувшегося м X шт.
						</Th>
						<Th style={{ verticalAlign: 'middle' }} maxW="200px">
							ФИО принявшего материал
						</Th>
						<Th style={{ verticalAlign: 'middle' }} w="120px">
							Подпись
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{isCreationMode && emptyRows(3, COLUMNS_COUNT)}
					{!isEditable ? (
						<>
							{preform_sgd_out?.map((item, idx) => (
								<Tr key={idx}>
									<Td>
										{item.date && dayjs(item.date).isValid()
											? dayjs(item.date).format('DD.MM.YY')
											: ''}
									</Td>
									<Td>
										{isPrinting ? (
											item?.product_tech_map_receiver?.number
										) : (
											<Link
												to={LINK_TO_TECH_CARD_INFO(
													Number(item?.product_tech_map_receiver?.number),
												)}
												target="_blank"
											>
												<chakra.p color="primary.400">
													{item?.product_tech_map_receiver?.number}
												</chakra.p>
											</Link>
										)}
									</Td>
									<Td>{item.count}</Td>
									<Td>{item.fio_receiver}</Td>
									<Td>{item.fio_sender}</Td>
									<Td></Td>
									<Td>
										{' '}
										{item.date && dayjs(item.date).isValid()
											? dayjs(item.date).format('DD.MM.YY')
											: ''}
									</Td>
									<Td>{item.return_count}</Td>
									<Td>{item.fio_receiver_returned}</Td>
									<Td></Td>
								</Tr>
							))}
							{isPrinting && emptyRows(rows, COLUMNS_COUNT)}
						</>
					) : (
						preform_sgd_out?.map((field, index) => (
							<Tr key={field.id ?? index}>
								<Td>
									<Controller
										name={`preform_sgd_out.${index}.date`}
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<DatePicker
												status={error ? 'error' : ''}
												onChange={onChange}
												defaultValue={
													field.date && dayjs(field.date).isValid()
														? dayjs(field.date)
														: undefined
												}
												format={'DD.MM.YY'}
												name={`preform_sgd_out.${index}.date`}
												autoFocus={
													(focusedBlock?.block === 'select_date_conservation' ||
														focusedBlock?.block === 'SGDOut') &&
													!value
												}
												style={{ width: '100%' }}
											/>
										)}
									/>
								</Td>
								<Td>
									{isEditable || isCreationMode ? (
										<MKSelectPreform
											name={`preform_sgd_out.${index}.product_tech_map_receiver.number`}
											onSelect={(item) => {
												setValue(
													`preform_sgd_out.${index}.product_tech_map_receiver_id`,
													item.id!,
												);
												setValue(
													`preform_sgd_out.${index}.product_tech_map_receiver.id`,
													item.number!,
												);
												setValue(
													`preform_sgd_out.${index}.product_tech_map_receiver.number`,
													item.number!,
												);
											}}
										/>
									) : (
										<Text>{field?.product_tech_map_receiver?.number}</Text>
									)}
								</Td>
								<Td>
									{isEditable ? (
										<Controller
											name={`preform_sgd_out.${index}.count`}
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => {
												return (
													<Input
														flex={1}
														isInvalid={!!error}
														onChange={onChange}
														value={convertNumberToNumberStringWithComma(
															String(value).replace(/[^0-9,.хx]/g, ''),
														)}
														name={`preform_sgd_out.${index}.count`}
													/>
												);
											}}
										/>
									) : (
										field?.count
									)}
								</Td>
								<Td>
									{isEditable ? (
										<Controller
											name={`preform_sgd_out.${index}.fio_receiver`}
											control={control}
											render={({ field: { value, onChange } }) => (
												<Select
													allowClear
													filterOption={(input, option) =>
														((option?.label ?? '') as string)
															.toLowerCase()
															.includes(input.toLowerCase())
													}
													showSearch
													placeholder="Выберите ФИО"
													optionFilterProp="children"
													onChange={(e) => {
														onChange(e);
														setValue(
															`preform_sgd_out.${index}.fio_receiver`,
															e
																? employee?.find((el) => el.value === Number(e))?.label
																: '',
														);
													}}
													value={value}
													options={employee}
													style={{ whiteSpace: 'nowrap', width: '100%' }}
													className={styles['custom-select']}
												/>
											)}
										/>
									) : (
										<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
											{field.fio_receiver}
										</Text>
									)}
								</Td>
								<Td>
									{isEditable ? (
										<Controller
											name={`preform_sgd_out.${index}.fio_sender`}
											control={control}
											render={({ field: { value, onChange } }) => (
												<Select
													allowClear
													filterOption={(input, option) =>
														((option?.label ?? '') as string)
															.toLowerCase()
															.includes(input.toLowerCase())
													}
													showSearch
													placeholder="Выберите ФИО"
													optionFilterProp="children"
													onChange={(e) => {
														onChange(e);
														setValue(
															`preform_sgd_out.${index}.fio_sender`,
															e
																? employee?.find((el) => el.value === Number(e))?.label
																: '',
														);
													}}
													value={value}
													options={employee}
													style={{ whiteSpace: 'nowrap', width: '100%' }}
													className={styles['custom-select']}
												/>
											)}
										/>
									) : (
										<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
											{field.fio_receiver}
										</Text>
									)}
								</Td>
								<Td></Td>
								<Td>
									<Controller
										name={`preform_sgd_out.${index}.return_date`}
										control={control}
										render={({ field: { onChange }, fieldState: { error } }) => (
											<DatePicker
												status={error ? 'error' : ''}
												onChange={onChange}
												defaultValue={
													field.date && dayjs(field.date).isValid()
														? dayjs(field.date)
														: undefined
												}
												format={'DD.MM.YY'}
												name={`preform_sgd_out.${index}.return_date`}
												style={{ width: '100%' }}
											/>
										)}
									/>
								</Td>
								<Td>
									{isEditable ? (
										<Controller
											name={`preform_sgd_out.${index}.return_count`}
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => {
												return (
													<Input
														flex={1}
														isInvalid={!!error}
														onChange={onChange}
														value={convertNumberToNumberStringWithComma(
															String(value).replace(/[^0-9,.хx]/g, ''),
														)}
														name={`preform_sgd_out.${index}.return_count`}
													/>
												);
											}}
										/>
									) : (
										field?.return_count
									)}
								</Td>
								<Td>
									{isEditable ? (
										<Controller
											name={`preform_sgd_out.${index}.fio_receiver_returned`}
											control={control}
											render={({ field: { value, onChange } }) => (
												<Select
													allowClear
													filterOption={(input, option) =>
														((option?.label ?? '') as string)
															.toLowerCase()
															.includes(input.toLowerCase())
													}
													showSearch
													placeholder="Выберите ФИО"
													optionFilterProp="children"
													onChange={(e) => {
														onChange(e);
														setValue(
															`preform_sgd_out.${index}.fio_receiver_returned`,
															e
																? employee?.find((el) => el.value === Number(e))?.label
																: '',
														);
													}}
													value={value}
													options={employee}
													style={{ whiteSpace: 'nowrap', width: '100%' }}
													className={styles['custom-select']}
												/>
											)}
										/>
									) : (
										<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
											{field.fio_receiver_returned}
										</Text>
									)}
								</Td>
								<Td></Td>
							</Tr>
						))
					)}
				</Tbody>
			</Table>
		</Flex>
	);
};
