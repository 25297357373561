import { format } from 'date-fns';
import dayjs from 'dayjs';
import _debounce from 'lodash/debounce';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TechCardsTable } from '@/entities';
import { TECH_CARD_ASSEMBLY_NEW, TECH_CARD_NEW, TECH_CARD_PREFORM_NEW, UserRoles } from '@/shared/core';
import { useAppSelector } from '@/shared/state';
import {
	GetAssemblyTechMapsApiArg,
	GetPreformTechMapsApiArg,
	GetPreformTechMapsApiResponse,
	GetTechMapsApiArg,
	useGetAssemblyTechMapsQuery,
	useGetPreformTechMapsQuery,
	useGetTechMapListExcelMutation,
	useGetTechMapsQuery,
	useLazyGetAssemblyTechMapsExcelQuery,
} from '@/shared/state/api/swagger';

import { MainContentLoader, Search, showErrorToast } from '@/shared';
import { FilterModal } from '@/widgets';
import {
	Button,
	chakra,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	TextHeader,
	useToast,
	Flex,
	Tabs,
	Tab,
	TabList,
} from '@chakra-ui-kraud/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MadIcon } from 'madsoft-icons';

import { Layout } from '@/widgets';
import styles from './tech-cards-page.module.scss';
import { isEmpty } from 'lodash';
import { TechCardsFiltersArg, TechMapData, TechMapType } from '@/entities/tables/tech-card';

const DEFAULT_QUERY_PARAMS: TechCardsFiltersArg = {
	pageSize: 32,
	currentPage: 1,
	orderBy: 'created_at',
	orderDir: 'desc',
};

function toTechMapData(preformResponse: GetPreformTechMapsApiResponse | undefined): TechMapData | undefined {
	if (!preformResponse) return undefined;

	return {
		...preformResponse,
		payload: preformResponse.payload.map((preform) => ({
			id: preform.id,
			number: preform.number || 1,
			current_product_operation: preform.current_preform_operation,
			status: preform.status,
			created_at: preform.created_at,
			storage_at: preform.storage_at,
			current_details_count: preform.current_details_count,
			wasted: preform.wasted,
			remaining_count: preform.remaining_count,

			num_gost: preform.preform_num?.gost,
			num_gost_sortament: preform.preform_num?.gost_na_sortament,
			num: preform.preform_num?.num,
			num_sortament: preform.preform_num?.sortament,
			preform_name: preform.preform?.name,
			return_count: preform.preform_plan?.return_count,
			num_symbol: preform.preform_num?.symbol,

			product: undefined,
		})),
	};
}

export const TechCardsPage: FC = () => {
	const [tabIndex, setTabIndex] = useState(0);
	const types = ['product', 'assembly', 'preform'] as TechMapType[];
	const currentType = types[tabIndex];

	const [queryParams, setQueryParams] = useState<TechCardsFiltersArg>(DEFAULT_QUERY_PARAMS);

	const handleTabsChange = (index: number) => {
		setTabIndex(index);
		setQueryParams(DEFAULT_QUERY_PARAMS);
	};

	const toast = useToast();
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const [getAssemblyCardsExcelByFilters, { isLoading: isGetAssemblyExcelLoading }] =
		useLazyGetAssemblyTechMapsExcelQuery();
	const [getProductCardsExcelByFilters, { isLoading: isGetProductExcelLoading }] = useGetTechMapListExcelMutation();

	const [searchTerm, setSearchTerm] = useState<string>('');

	const [isFiltersModalOpen, setFiltersModalOpen] = useState(false);
	const {
		data: productData,
		isLoading: isProductLoading,
		isFetching: isProductFetching,
		isError: isGetProductError,
	} = useGetTechMapsQuery(queryParams as GetTechMapsApiArg, {
		refetchOnMountOrArgChange: true,
		skip: currentType !== 'product',
	});
	const {
		assemblyData,
		isLoading: isAssemblyLoading,
		isFetching: isAssemblyFetching,
		isError: isAssemblyMapGetError,
	} = useGetAssemblyTechMapsQuery(queryParams as GetAssemblyTechMapsApiArg, {
		refetchOnMountOrArgChange: true,
		selectFromResult: (result: any) => ({
			...result,
			assemblyData: {
				...result.data,
				payload: result.data?.payload?.map((item: any) => ({
					...item,
					product: { ...item.assembly },
					current_product_operation: { ...item.current_assembly_operation },
				})),
			},
		}),
		skip: currentType !== 'assembly',
	});
	const {
		data: preformData,
		isLoading: isPreformLoading,
		isFetching: isPreformFetching,
		isError: isPreformMapGetError,
	} = useGetPreformTechMapsQuery(queryParams as GetPreformTechMapsApiArg, {
		refetchOnMountOrArgChange: true,
		skip: currentType !== 'preform',
	});

	const queries = {
		assembly: {
			isLoading: isAssemblyLoading,
			isFetching: isAssemblyFetching,
			isGetError: isAssemblyMapGetError,
			data: assemblyData as TechMapData,
			isGetExcelLoading: isGetAssemblyExcelLoading,
			getCardsExcelByFilters: getAssemblyCardsExcelByFilters,
		},
		product: {
			isLoading: isProductLoading,
			isFetching: isProductFetching,
			isGetError: isGetProductError,
			data: productData,
			isGetExcelLoading: isGetProductExcelLoading,
			getCardsExcelByFilters: getProductCardsExcelByFilters,
		},
		preform: {
			isLoading: isPreformLoading,
			isFetching: isPreformFetching,
			isGetError: isPreformMapGetError,
			data: toTechMapData(preformData),
			/** @todo Исправить */ isGetExcelLoading: isGetAssemblyExcelLoading,
			/** @todo Исправить */ getCardsExcelByFilters: getAssemblyCardsExcelByFilters,
		},
	};

	const { data, isLoading, isFetching, isGetError, isGetExcelLoading, getCardsExcelByFilters } = queries[currentType];

	useEffect(() => {
		if (isGetError) showErrorToast(toast, { description: 'При получении Техкарт произошла ошибка' });
	}, [isGetError]);

	const navigate = useNavigate();

	const handleCreateClick = (path: string) => {
		navigate(path);
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQueryParams((p: any) => ({
			...p,
			stringToSearch: e.target.value.trim() || undefined,
			currentPage: 1,
		}));
	};

	const debouncedSearch = useCallback(_debounce(handleSearch, 700), []);

	const handleFilterFormSubmit = (values: TechCardsFiltersArg) => {
		setQueryParams((prev: any) => ({
			...prev,
			...values,
			numStrict: values?.numStrict || undefined,
			numSymbolStrict: values?.numSymbolStrict || undefined,
			symbol: values?.symbol || undefined,
			[`${currentType}Name`]: values?.[`${currentType}Name` as keyof TechCardsFiltersArg] || undefined,
			[`${currentType}Symbol`]: values?.[`${currentType}Symbol` as keyof TechCardsFiltersArg] || undefined,
			currentOperationsNames: isEmpty(values?.currentOperationsNames) ? undefined : values.currentOperationsNames,
			statuses: isEmpty(values.statuses) ? undefined : values.statuses,
			wasted: values.wasted ?? undefined,
			createdAtFrom: values.createdAtFrom ? dayjs(values.createdAtFrom).format('YYYY-MM-DD') : undefined,
			createdAtTo: values.createdAtTo ? dayjs(values.createdAtTo).format('YYYY-MM-DD') : undefined,
			storageAtFrom: values.storageAtFrom ? dayjs(values.storageAtFrom).format('YYYY-MM-DD') : undefined,
			storageAtTo: values.storageAtTo ? dayjs(values.storageAtTo).format('YYYY-MM-DD') : undefined,
		}));

		setFiltersModalOpen(false);
	};

	const handleGetListExcelTechCards = useCallback(() => {
		getCardsExcelByFilters(queryParams as GetTechMapsApiArg & GetAssemblyTechMapsApiArg)
			.unwrap()
			.then((res) => {
				const href = window.URL.createObjectURL(new Blob([res]));
				const link = document.createElement('a');
				link.href = href;
				link.download = `Map_list_${format(new Date(), 'dd-MM-yyyy')}.xlsx`;
				link.click();
				link.remove();
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При скачивании Excel произошла ошибка' });
			});
	}, [getCardsExcelByFilters, queryParams, toast]);

	return (
		<>
			<Layout
				title={
					<TextHeader className={styles['tech-cards__title']} variant="h1">
						Маршрутные карты
					</TextHeader>
				}
				headerLeftButton={
					userRole !== UserRoles.user && (
						<Menu>
							<MenuButton as={Button} variant="ghost" rightIcon={<ChevronDownIcon />}>
								Создать карту...
							</MenuButton>
							<MenuList p={2} zIndex="1000" fontWeight="500" minW="190" borderRadius="base">
								<MenuItem py={2} px={4} onClick={() => handleCreateClick(TECH_CARD_NEW)}>
									На деталь
								</MenuItem>
								<MenuItem py={2} px={4} onClick={() => handleCreateClick(TECH_CARD_ASSEMBLY_NEW)}>
									Сборочную
								</MenuItem>
								<MenuItem py={2} px={4} onClick={() => handleCreateClick(TECH_CARD_PREFORM_NEW)}>
									Заготовительную
								</MenuItem>
							</MenuList>
						</Menu>
					)
				}
				search={
					<Search
						handleChange={(e) => {
							debouncedSearch(e);
							setSearchTerm(e.target.value);
						}}
						value={searchTerm}
					/>
				}
				filters={
					<chakra.div display="flex" justifyContent="center" alignItems="center" gap="8px">
						<Button
							size="md"
							variant="ghost"
							colorScheme="tertiary"
							rightIcon={
								<MadIcon module="basic" mode="default" size="default" type="outline" name="filter" />
							}
							minW="125px"
							onClick={() => setFiltersModalOpen(true)}
						>
							Фильтры
						</Button>
						<Button
							size="md"
							variant="ghost"
							colorScheme="tertiary"
							rightIcon={
								isGetExcelLoading ? (
									<Spinner size="xs" />
								) : (
									<MadIcon
										module="basic"
										mode="default"
										size="default"
										type="outline"
										name="arrow-down-tray"
									/>
								)
							}
							minW="125px"
							onClick={handleGetListExcelTechCards}
						>
							{isGetExcelLoading ? 'Обработка данных' : 'Выгрузить данные'}
						</Button>
					</chakra.div>
				}
			>
				{isFetching && !isLoading ? <MainContentLoader /> : <></>}

				<TechCardsTable
					type={currentType}
					superHeader={
						<Flex justify="space-between" alignItems="center" mb="1rem">
							<Tabs index={tabIndex} onChange={handleTabsChange}>
								<TabList>
									<Tab>На деталь</Tab>
									<Tab>Сборочные</Tab>
									<Tab>Заготовительные</Tab>
								</TabList>
							</Tabs>
						</Flex>
					}
					data={data}
					setQueryParams={setQueryParams}
					isLoading={isLoading}
					isStatusLoading={isFetching}
					queryParams={queryParams}
				/>
			</Layout>
			{isFiltersModalOpen && (
				<FilterModal
					type={currentType}
					isOpen={isFiltersModalOpen}
					onClose={() => setFiltersModalOpen(false)}
					onSubmit={handleFilterFormSubmit}
					defaultValue={queryParams}
				/>
			)}
		</>
	);
};
