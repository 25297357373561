import { useAppSelector, UserRoles, useTechCard } from '@/shared';
import { FormPreformModel } from '@/widgets/tech-card/model/preform.types';
import { Table, Th, Tr, Td, Thead, Tbody, Text, Input, Flex } from '@chakra-ui-kraud/react';
import { FC, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './custom-operations.module.scss';
import { isOperationDone, isOperationDoneAndEmptyField } from '../lib/operations-utils';
import { OperationsPreformBlockProps } from '../model/operations.types';
import {
	PreformOperationResponse,
	useGetEmployeeInitialsQuery,
	useGetPreformTechMapByNumberQuery,
} from '@/shared/state/api/swagger';
import { selectEmployesForOptions } from '@/entities/employee';
import dayjs from 'dayjs';
import { DatePicker, Select } from 'antd';
import classNames from 'classnames';
import { byStepId } from '@/widgets/tech-card/lib/sort-by-step-id';
import { useParams } from 'react-router-dom';
import { convertNumberToNumberStringWithComma } from '@/shared/core/utils/convert-string-to-number-string';
import defaultStyles from '../operations/operations.module.scss';

export const OperationsPreformBlock: FC<OperationsPreformBlockProps> = ({ isEditable }) => {
	const { control, setValue, getValues } = useFormContext<FormPreformModel>();
	const { isCreationMode } = useTechCard();
	const mapNumber = Number(useParams().number);
	const { data: preformMap } = useGetPreformTechMapByNumberQuery(
		{ preformTechMapNumber: mapNumber },
		{
			skip: !mapNumber || Number.isNaN(mapNumber),
		},
	);
	const { preform_operations: fields } = getValues();
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);
	const { options: employees } = useGetEmployeeInitialsQuery(undefined, {
		selectFromResult: (result) => ({ ...result, options: selectEmployesForOptions(result?.data) }),
	});

	const sortedOperationsTypes = useMemo(() => (fields ? [...fields].sort(byStepId) : []), [fields]);
	const emptyRows = useCallback((length: number) => {
		const rows = new Array(length).fill({});
		return rows.map((_, idx) => (
			<Tr key={idx}>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
			</Tr>
		));
	}, []);

	const customIndex = Number(preformMap?.preform_operations?.length);
	const customOperations = [{}, {}] as FormPreformModel['preform_operations'];

	const renderOperationRow = (field: PreformOperationResponse, index: number) => {
		return (
			<Tr key={field.id ?? index}>
				<Td style={{ textAlign: 'left' }}>
					<Controller
						name={`preform_operations.${index}.step_id`}
						control={control}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<Input
								backgroundColor="transparent"
								tabIndex={7}
								isInvalid={!!error}
								onChange={onChange}
								value={value}
								name={`preform_operations.${index}.step_id`}
							/>
						)}
					/>{' '}
				</Td>
				<Td style={{ textAlign: 'left' }}>
					<Controller
						name={`preform_operations.${index}.name`}
						control={control}
						render={({ field: fieldData, fieldState: { error } }) => {
							return <Input tabIndex={7} isInvalid={!!error} {...fieldData} h="100%" flex="1" />;
						}}
					/>{' '}
				</Td>
				<Td style={{ textAlign: 'left' }}>
					<Controller
						name={`preform_operations.${index}.date`}
						control={control}
						render={({ field: { onChange }, fieldState: { error } }) => (
							<DatePicker
								style={{ width: '100%', height: '100%' }}
								tabIndex={7}
								status={error ? 'error' : ''}
								onChange={onChange}
								format={'DD.MM.YYYY'}
								defaultValue={field.date && dayjs(field.date).isValid() ? dayjs(field.date) : undefined}
								name={`preform_operations.${index}.date`}
							/>
						)}
					/>
				</Td>
				<Td style={{ textAlign: 'left' }}>
					{!isOperationDone(field) || userRole === UserRoles.admin ? (
						<Controller
							name={`preform_operations.${index}.count_in_number`}
							control={control}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<Input
									backgroundColor="transparent"
									tabIndex={7}
									onChange={(e) => {
										onChange(
											Number.isNaN(e.target.value) || !e.target.value ? null : e.target.value,
										);
									}}
									isInvalid={!!error}
									value={convertNumberToNumberStringWithComma(
										String(value).replace(/[^0-9,.хx]/g, ''),
									)}
									name={`preform_operations.${index}.count_in_number`}
								/>
							)}
						/>
					) : (
						<Text
							p="6px"
							fontWeight="normal"
							textAlign={!isOperationDoneAndEmptyField(field, field.count_in_number) ? 'left' : 'center'}
						>
							{!isOperationDoneAndEmptyField(field, field.count_in_number) ? field.count_in_number : '-'}
						</Text>
					)}
				</Td>
				<Td style={{ textAlign: 'left' }}>
					<Controller
						name={`preform_operations.${index}.fio`}
						control={control}
						render={({ field: { value, onChange } }) => (
							<Select
								tabIndex={7}
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								allowClear
								showSearch
								placeholder="Выберите ФИО"
								optionFilterProp="children"
								value={value}
								onChange={(e) => {
									onChange(e);
									setValue(
										`preform_operations.${index}.fio`,
										e ? employees.find((el) => el.value === Number(e))?.label : '',
									);
								}}
								options={employees}
								style={{ width: '100%' }}
								className={classNames(styles['custom-select'])}
							/>
						)}
					/>
				</Td>
				<Td>
					{!isOperationDone(field) || userRole === UserRoles.admin ? (
						<Controller
							name={`preform_operations.${index}.count_out_number`}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
								formState: { defaultValues },
							}) => (
								<Input
									backgroundColor="transparent"
									tabIndex={7}
									onChange={(e) => {
										const inputValue =
											Number.isNaN(e.target.value) || !e.target.value ? null : e.target.value;
										onChange(inputValue);
										if (index >= customIndex) {
											return;
										}

										if (
											!defaultValues?.preform_operations?.[index + 1]?.count_in_number &&
											index !== customIndex - 1
										) {
											// если это не последняя операция, расчет годных (штук) в этой операции переходит в грамм, штук следующей НЕ ОТМЕНЕННОЙ операции
											setValue(
												`preform_operations.${index + 1}.count_in_number`,
												convertNumberToNumberStringWithComma(inputValue),
											);
										}
									}}
									isInvalid={!!error}
									value={convertNumberToNumberStringWithComma(
										String(value).replace(/[^0-9,.хx]/g, ''),
									)}
									name={`preform_operations.${index}.count_out_number`}
								/>
							)}
						/>
					) : (
						<Text
							p="6px"
							fontWeight="normal"
							textAlign={!isOperationDoneAndEmptyField(field, field.count_out_number) ? 'left' : 'center'}
						>
							{!isOperationDoneAndEmptyField(field, field.count_out_number)
								? field.count_out_number
								: '-'}
						</Text>
					)}
				</Td>
				<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
					{!field.is_canceled ? (
						<Controller
							name={`preform_operations.${index}.wasted`}
							control={control}
							render={({ field: { onChange, value, name }, fieldState: { error } }) => (
								<Input
									tabIndex={7}
									isInvalid={!!error}
									onChange={onChange}
									// @ts-expect-error TODO: fix after swagger update
									value={
										value && (Number.isNaN(Number(value)) || Number(value) > 0)
											? convertNumberToNumberStringWithComma(
													String(value).replace(/[^0-9,.хx]/g, ''),
											  )
											: null
									}
									name={name}
									className={field.is_canceled ? styles['field-disabled'] : ''}
								/>
							)}
						/>
					) : (
						<Text p="6px" fontWeight="normal" textAlign="left">
							{!field.wasted || Number(field.wasted) === 0 ? '' : field.wasted}
						</Text>
					)}
				</Td>{' '}
				<Td></Td>
				<Td></Td>
			</Tr>
		);
	};

	return (
		<Flex className={defaultStyles['operations']}>
			<Table>
				<Thead>
					<Tr>
						<Th colSpan={2}>Операции</Th>
						<Th colSpan={5}>Результаты работы</Th>
						<Th colSpan={2}>Подписи</Th>
					</Tr>
					<Tr>
						<Th w="60px" style={{ textAlign: 'left' }}>
							№
						</Th>
						<Th w="400px">Наименование</Th>
						<Th w="140px">Дата</Th>
						<Th maxW="200px">Выдано в работу м X шт.</Th>
						<Th maxW="225px">ФИО исполнителя</Th>
						<Th w="150px">Годных м X шт</Th>
						<Th w="120px">Брак м X шт</Th>
						<Th w="120px">Исполнитель</Th>
						<Th w="120px">Кладовщик</Th>
					</Tr>
				</Thead>
				<Tbody>
					{!isEditable && !isCreationMode && (
						<>
							{preformMap?.preform_operations?.map((operation) => {
								return (
									<Tr
										key={operation.id}
										className={operation.is_canceled ? styles['field-disabled'] : ''}
									>
										<Td style={{ textAlign: 'left' }}>
											{operation.step_id < 10 ? '0' + operation.step_id : operation.step_id}
										</Td>
										<Td style={{ textAlign: 'left', padding: '0px 2px 0px 8px' }}>
											<Text>{operation.name}</Text>
										</Td>
										<Td>
											{dayjs(operation.date).isValid()
												? dayjs(operation.date).format('DD.MM.YYYY')
												: ''}
										</Td>
										<Td>
											{!isOperationDoneAndEmptyField(operation, operation.count_in_number)
												? operation.count_in_number
												: '-'}
										</Td>
										<Td>
											{!isOperationDoneAndEmptyField(operation, operation.fio)
												? operation.fio
												: '-'}
										</Td>
										<Td>
											{!isOperationDoneAndEmptyField(operation, operation.count_out_number)
												? operation.count_out_number
												: '-'}
										</Td>
										<Td>
											{!operation.wasted || Number(operation.wasted) === 0
												? ''
												: operation.wasted}
										</Td>
										<Td></Td>
										<Td></Td>
									</Tr>
								);
							})}
							{emptyRows(2)}
						</>
					)}
					{!isEditable && isCreationMode && sortedOperationsTypes?.length ? (
						<>
							{sortedOperationsTypes?.map((operation) => (
								<Tr
									key={operation.id}
									className={operation.is_canceled ? styles['field-disabled'] : ''}
								>
									<Td style={{ textAlign: 'left' }}>
										{operation.step_id < 10 ? '0' + operation.step_id : operation.step_id}
									</Td>
									<Td style={{ textAlign: 'left' }}>{operation.name}</Td>
									<Td></Td>
									<Td></Td>
									<Td></Td>
									<Td></Td>
									<Td></Td>
									<Td></Td>
									<Td></Td>
								</Tr>
							))}
							{emptyRows(2)}
						</>
					) : (
						<></>
					)}
					{isEditable && !isCreationMode && (
						<>
							{sortedOperationsTypes?.map((field, index) => renderOperationRow(field, index))}
							{/* Пустые строки в таблице */}
							{customOperations?.map((field, index) => {
								return renderOperationRow(field, index + customIndex);
							})}
						</>
					)}
				</Tbody>
			</Table>
		</Flex>
	);
};
