import { FC, useRef } from 'react';

import { NumModalProps } from './num-modal.types';

import { NumCatalogForm } from '@/entities';
import { SideModal } from '@/shared/components';
import { showErrorToast, showSuccessToast } from '@/shared/components/toasts';
import {
	NumCatalogCreateRequest,
	NumCatalogResponse,
	NumCatalogUpdateRequest,
	usePostNumCatalogMutation,
	usePutNumCatalogMutation,
} from '@/shared/state/api/swagger';
import { format, parse } from 'date-fns';

import { Button, ButtonGroup, useToast } from '@chakra-ui-kraud/react';

export const NumModal: FC<NumModalProps> = ({ isOpen, onClose, onBlur, title, selectedNum, variant, refetchNums }) => {
	const formRef = useRef<HTMLFormElement>();
	const [createNum, { isLoading: isCreateNumLoading }] = usePostNumCatalogMutation();
	const [updateNum, { isLoading: isUpdateNumLoading }] = usePutNumCatalogMutation();
	const toast = useToast();

	const handleSubmit = (data: NumCatalogResponse) => {
		const formattedData: NumCatalogResponse = {
			...data,
			date_of_manufacture: data?.date_of_manufacture || undefined,
			supplier: data?.supplier?.name ? { name: data?.supplier?.name, id: -1 } : undefined,
			manufacturer: data?.manufacturer?.name ? { name: data?.manufacturer?.name, id: -1 } : undefined,
			invoice:
				data?.invoice?.number || data?.invoice?.date
					? {
							number: data?.invoice?.number || '',
							date: data?.invoice.date,
							id: -1,
					  }
					: undefined,
			valid_until: data?.valid_until || undefined,
		};

		if (variant === 'create') {
			createNum({
				numCatalogCreateRequest: formattedData as NumCatalogCreateRequest,
			})
				.unwrap()
				.then(() => {
					refetchNums();
					showSuccessToast(toast, { description: 'НУМ создан' });
					onClose();
				})
				.catch((e) => {
					if (e.status === 469) {
						showErrorToast(toast, {
							description: `Данный номер С/Ф уже создан с датой от ${format(
								parse(formattedData.invoice!.date as string, 'yyyy-MM-dd', new Date()),
								'dd.MM.yyyy',
							)}. Проверьте, что вы указали верный номер С/Ф или сначала отредактируйте дату в уже созданном материале.`,
						});
					} else {
						showErrorToast(toast, { description: 'При создании НУМ произошла ошибка' });
					}
				});
		} else if (variant === 'edit' && selectedNum?.id) {
			updateNum({
				numCatalogUpdateRequest: {
					...formattedData,
					num: Number(data.num),
					id: selectedNum?.id,
				} as NumCatalogUpdateRequest,
			})
				.unwrap()
				.then(() => {
					refetchNums();
					showSuccessToast(toast, { description: 'НУМ отредактирован' });
					onClose();
				})
				.catch(() => {
					showErrorToast(toast, {
						description: 'При редактировании НУМ произошла ошибка',
					});
				});
		}
	};

	return (
		<SideModal
			title={title}
			isOpen={isOpen}
			onClose={onClose}
			footer={
				<ButtonGroup>
					<Button size="md" variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => {
							formRef?.current?.submitForm();
						}}
						isDisabled={isCreateNumLoading || isUpdateNumLoading}
					>
						{variant === 'edit' ? 'Сохранить' : 'Добавить материал'}
					</Button>
				</ButtonGroup>
			}
			onBlur={onBlur ?? onClose}
		>
			<NumCatalogForm variant={variant} onSubmit={handleSubmit} reference={formRef} selectedNum={selectedNum} />
		</SideModal>
	);
};
