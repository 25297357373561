import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { CalendarSinglePickerProps } from './calendar-single-picker.types';

import {
	CalendarContainer,
	CalendarDatepicker,
	CalendarDatepickerTrigger,
	CalendarDaysGrid,
	CalendarHeader,
	chakra,
	IconButton,
} from '@chakra-ui-kraud/react';
import { format, parse } from 'date-fns';
import { MadIcon } from 'madsoft-icons';
import { CustomInput } from '../custom-input';

import styles from './calendar-single-picker.module.scss';
import { useShowTooltip } from '@/shared/hooks/use-show-tooltip';

export const CalendarSinglePicker: FC<CalendarSinglePickerProps> = ({
	defaultDate,
	label,
	onSelectDate,
	onResetDate,
	clearable = true,
	maxDate,
	showTooltip = false,
	tooltipContent,
	arrowTooltip = false,
	styleTooltip,
	isInvalid = false,
	width,
	value,
	hideTooltipOnClick = false,
}) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const [date, setDate] = useState<Date[]>(defaultDate ? [parse(defaultDate, 'yyyy-MM-dd', new Date())] : []);
	const [isOpen, setIsOpen] = useState(false);

	const onSelectFromCalendar = useCallback((date: Date) => {
		setDate([date]);
		setIsOpen(false);
	}, []);
	const onResetClick = useCallback(() => {
		setIsOpen(false);
		setDate([]);

		onResetDate && onResetDate();
	}, []);
	const onInputClick = useCallback(() => {
		setIsOpen(true);
	}, []);
	const onIconClick = useCallback(() => {
		setIsOpen((prev) => !prev);
	}, []);

	useEffect(() => {
		if (date[0]) {
			onSelectDate(format(date[0], 'yyyy-MM-dd'));
		}
	}, [date, onSelectDate]);

	useEffect(() => {
		if (value !== undefined && (!date[0] || (date[0] && format(date[0], 'yyyy-MM-dd') !== value))) {
			setDate(!value ? [] : [parse(value, 'yyyy-MM-dd', new Date())]);
		}
	}, [value]);

	useEffect(() => {
		if (containerRef.current && isOpen) {
			const onClick = (e: MouseEvent) => {
				for (const element of e.composedPath()) {
					if (element === containerRef.current) {
						return;
					}
				}

				setIsOpen(false);
			};

			window.addEventListener('mousedown', onClick);

			return () => {
				window.removeEventListener('mousedown', onClick);
			};
		}
	}, [isOpen, containerRef]);

	const [isTooltipVisible, setIsTooltipVisible] = useShowTooltip(showTooltip);

	return (
		<chakra.div ref={containerRef}>
			<CalendarDatepicker
				maxDate={maxDate}
				isOpen={isOpen}
				closeOnSelect
				selectedDates={date}
				onSelect={onSelectFromCalendar}
				range={false}
				maxSelectedCount={1}
			>
				<CalendarDatepickerTrigger>
					<CustomInput
						height="42px"
						width={width}
						cursor={'pointer'}
						labelonTop
						label={label}
						isReadOnly
						value={date[0] ? format(date[0], 'dd.MM.yyyy') : ''}
						placeholder={label}
						onClick={(e) => {
							hideTooltipOnClick && setIsTooltipVisible(false);
							onInputClick?.();
						}}
						showTooltip={isTooltipVisible}
						arrowTooltip={arrowTooltip}
						tooltipContent={tooltipContent}
						styleTooltip={styleTooltip}
						isInvalid={isInvalid}
						rightIcon={
							<chakra.div display="flex" mr={defaultDate ? '30px' : ''}>
								{defaultDate && clearable ? (
									<IconButton
										aria-label="test"
										size="sm"
										variant="ghost"
										colorScheme="gray"
										icon={
											<MadIcon
												name="x-circle"
												size="default"
												mode="default"
												type="outline"
												module="basic"
											/>
										}
										onClick={onResetClick}
									/>
								) : (
									<></>
								)}
								<IconButton
									aria-label=""
									size="sm"
									variant="ghost"
									colorScheme="gray"
									icon={
										<MadIcon
											name="calendar"
											size="default"
											mode="default"
											type="outline"
											module="basic"
										/>
									}
									onClick={onIconClick}
								/>
							</chakra.div>
						}
					/>
				</CalendarDatepickerTrigger>
				<chakra.div className={styles['calendar_container']}>
					<CalendarContainer>
						<CalendarHeader />
						<CalendarDaysGrid />
					</CalendarContainer>
				</chakra.div>
			</CalendarDatepicker>
		</chakra.div>
	);
};
