import { TechCardStatus, TechCardStatuses } from '@/shared';

export const MAP_STATUS_TO_NAME: Record<TechCardStatus, string | undefined> = {
	[TechCardStatuses.released]: 'Отгружено',
	[TechCardStatuses.progress]: 'В работе',
	[TechCardStatuses.accepted_at_sgd]: 'Принято на СГД',
	[TechCardStatuses.canceled]: 'Аннулирована',
	[TechCardStatuses.partially_issued]: 'Частично выдана',
	[TechCardStatuses.completed]: 'Завершена',
	[TechCardStatuses.accepted_at_storage]: 'На складе',
};

export const MAP_STATUS_TO_HEADER: Partial<Record<TechCardStatus, string | undefined>> = {
	[TechCardStatuses.progress]: 'Заполнение операции',
	[TechCardStatuses.accepted_at_sgd]: 'Выдача из СГД',
	[TechCardStatuses.partially_issued]: 'Выдача из СГД',
};
