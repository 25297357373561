import { Table, Tbody, Td, Tr, Text, chakra, Input, Thead, Th, Flex } from '@chakra-ui-kraud/react';
import styles from './custom-operations.module.scss';
import { FC, useCallback, useMemo } from 'react';
import { AssemblyOperationResponse, useGetEmployeeInitialsQuery } from '@/shared/state/api/swagger';
import { useFormValues } from '@/widgets/tech-card/lib/use-form-values';
import { Controller, useFormContext } from 'react-hook-form';
import { AssemblyConfig } from '@/widgets/tech-card/form-assembly-config';
import { COMPLECT_OPERATION_STEP_ID, useAppSelector, UserRoles, useTechCard } from '@/shared';
import { OperationsAssemblyBlockTypes } from '../model/operations.types';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { convertNumberToNumberStringWithDot } from '@/shared/core/utils/convert-string-to-number-string';
import clsx from 'clsx';

export const CustomOperationsAssemblyBlock: FC<OperationsAssemblyBlockTypes> = ({ isEditable }) => {
	const { assemblyCard: cardInfo, isCreationMode } = useTechCard();
	const UserRole = useAppSelector((state) => state.auth.userProfile?.role);
	const { control, setValue, getValues } = useFormContext<AssemblyConfig>();

	const { assembly_operations = [], assembly_products } = useFormValues();
	const defaultOperations = useMemo(
		() =>
			assembly_operations
				.filter((operation) => operation.is_default)
				.sort((a, b) => Number(a?.step_id) - Number(b?.step_id)),
		[assembly_operations],
	);

	const { data: employeeForSelect } = useGetEmployeeInitialsQuery();
	const employee = employeeForSelect?.map((el) => ({
		value: el.id,
		label: el.initials,
	}));

	const customIndex = Number(cardInfo?.assembly_operations?.length);

	const sortedOperationsTypes = useMemo(() => {
		return assembly_operations ? assembly_operations.sort((a, b) => Number(a?.step_id) - Number(b?.step_id)) : [];
	}, [assembly_operations]);

	const customOperations = useMemo(
		() => sortedOperationsTypes.filter((operation) => !operation.is_default),
		[sortedOperationsTypes],
	);

	// определение завершенной операции (если есть ФИО и дата)
	const isOperationDone = useCallback((operation: AssemblyOperationResponse) => {
		if (
			!!operation.fio?.length &&
			!!operation.date?.length &&
			!!operation.count_in_number &&
			!!operation.count_out_number
		) {
			return true;
		}
		return false;
	}, []);

	// определение завершенной операции (если есть ФИО и дата) и пустого поля
	const isOperationDoneAndEmptyField = useCallback(
		(operation: AssemblyOperationResponse, fieldValue: string | number | null | undefined) => {
			if (isOperationDone(operation) && !fieldValue) {
				return true;
			}
			return false;
		},
		[isOperationDone],
	);

	const emptyRows = (length: number) => {
		const rows = new Array(length).fill({});
		return rows.map((_, idx) => (
			<Tr key={idx}>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
			</Tr>
		));
	};

	const renderRowsContentOnCreate = useCallback((operation: AssemblyOperationResponse) => {
		return (
			<>
				<Td style={{ textAlign: 'left' }}>{operation.name}</Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
			</>
		);
	}, []);

	const renderRowsOnCreate = useCallback(
		(operations: AssemblyOperationResponse[] = []) => {
			return (
				<>
					{operations?.map((operation) => (
						<Tr key={operation.id} className={operation.is_canceled ? styles['field-disabled'] : ''}>
							<Td style={{ textAlign: 'left' }}>
								{operation.step_id < 10 ? '0' + operation.step_id : operation.step_id}
							</Td>
							{renderRowsContentOnCreate(operation)}
						</Tr>
					))}
				</>
			);
		},
		[renderRowsContentOnCreate],
	);

	const renderRowsContent = useCallback(
		(operation: AssemblyOperationResponse) => {
			return (
				<>
					<Td style={{ textAlign: 'left', padding: '0px 2px 0px 8px' }}>
						<chakra.div display="flex" justifyContent="space-between" alignItems="center" p={0}>
							<chakra.div>
								{operation.name}
								{operation.is_canceled ? '— операция отменена' : ''}
							</chakra.div>
						</chakra.div>
					</Td>
					<Td>
						<Text p="6px" fontWeight="normal" textAlign="center">
							{operation.date ? dayjs(operation.date).format('DD.MM.YYYY') : ''}
						</Text>
					</Td>
					<Td>
						{!isOperationDoneAndEmptyField(operation, operation.count_in_number)
							? operation.count_in_number
							: '-'}
					</Td>
					<Td>{operation.fio}</Td>
					<Td>
						{!isOperationDoneAndEmptyField(operation, operation.count_out_number)
							? operation.count_out_number
							: '-'}
					</Td>
					<Td>{!operation.wasted ? '' : operation.wasted}</Td>
					<Td></Td>
					<Td></Td>
				</>
			);
		},
		[isOperationDoneAndEmptyField],
	);

	const renderComplectRowsOnCreate = useCallback(() => {
		const complectOperations = assembly_products.map((product) => ({
			name: product?.product_name ? `Комплектовочная ${product?.product_name}` : '',
		})) as AssemblyOperationResponse[];

		return (
			sortedOperationsTypes?.[0] && (
				<>
					<Tr>
						<>
							<Td rowSpan={complectOperations.length} style={{ textAlign: 'left' }}>
								05
							</Td>
							{renderRowsContentOnCreate(complectOperations[0])}
						</>
					</Tr>
					{complectOperations.slice(1).map((operation) => (
						<Tr key={`${operation.step_id}_${operation.name}`}>{renderRowsContentOnCreate(operation)}</Tr>
					))}
				</>
			)
		);
	}, [assembly_products, renderRowsContentOnCreate, sortedOperationsTypes]);

	const renderRows = useCallback(() => {
		return (
			<>
				{customOperations.slice(assembly_products.length)?.map((operation) => (
					<Tr key={operation.id}>
						<Td style={{ textAlign: 'left' }}>
							{operation.step_id < 10 ? '0' + operation.step_id : operation.step_id}
						</Td>
						{renderRowsContent(operation)}
					</Tr>
				))}
			</>
		);
	}, [assembly_products.length, customOperations, renderRowsContent]);

	const renderComplectRows = useCallback(() => {
		return (
			sortedOperationsTypes?.[0] && (
				<>
					<Tr>
						<>
							<Td rowSpan={assembly_products.length} style={{ textAlign: 'left' }}>
								{'05'}
							</Td>
							{renderRowsContent(sortedOperationsTypes[0])}
						</>
					</Tr>
					{sortedOperationsTypes.slice(1, assembly_products.length).map((operation) => (
						<Tr key={operation.id}>{renderRowsContent(operation)}</Tr>
					))}
				</>
			)
		);
	}, [assembly_products.length, renderRowsContent, sortedOperationsTypes]);

	const renderEditModeRowsContent = useCallback(
		(field: AssemblyOperationResponse, index: number) => {
			return (
				<>
					<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
						{!field.is_canceled ? (
							<Controller
								name={`assembly_operations.${index}.name`}
								control={control}
								render={({ field: fieldData, fieldState: { error } }) => {
									return <Input tabIndex={7} isInvalid={!!error} {...fieldData} h="100%" flex="1" />;
								}}
							/>
						) : (
							<Text
								textAlign="left"
								padding={field.is_canceled ? '8px 1px 8px 6px' : ''}
								fontWeight="normal"
							>
								{field.name}
							</Text>
						)}
					</Td>
					<Td className={clsx(styles['date-style'], field.is_canceled && styles['field-disabled'])}>
						{!field.is_canceled ? (
							<Controller
								name={`assembly_operations.${index}.date`}
								control={control}
								render={({ field: { onChange }, fieldState: { error } }) => (
									<DatePicker
										style={{ width: '100%', padding: '0' }}
										tabIndex={7}
										status={error ? 'error' : ''}
										onChange={onChange}
										format={'DD.MM.YYYY'}
										defaultValue={
											field.date && dayjs(field.date).isValid() ? dayjs(field.date) : undefined
										}
										name={`assembly_operations.${index}.date`}
									/>
								)}
							/>
						) : (
							<Text fontWeight="normal" textAlign="center">
								{field.date ? dayjs(field.date).format('DD.MM.YYYY') : ''}
							</Text>
						)}
					</Td>
					<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
						{!field.is_canceled && (!isOperationDone(field) || UserRole === UserRoles.admin) ? (
							<Controller
								name={`assembly_operations.${index}.count_in_number`}
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										backgroundColor="transparent"
										tabIndex={7}
										onChange={onChange}
										isInvalid={!!error}
										value={value}
										name={`assembly_operations.${index}.count_in_number`}
									/>
								)}
							/>
						) : (
							<Text
								p="6px"
								fontWeight="normal"
								textAlign={
									!isOperationDoneAndEmptyField(field, field.count_in_number) ? 'left' : 'center'
								}
							>
								{!isOperationDoneAndEmptyField(field, field.count_in_number)
									? field.count_in_number
									: '-'}
							</Text>
						)}
					</Td>
					<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
						{!field.is_canceled ? (
							<Controller
								name={`assembly_operations.${index}.fio`}
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										tabIndex={7}
										filterOption={(input, option) =>
											`${option?.label ?? ''}`.toLowerCase().includes(input.toLowerCase())
										}
										allowClear
										showSearch
										placeholder="Выберите ФИО"
										optionFilterProp="children"
										value={value}
										onChange={(e) => {
											onChange(e);
											setValue(
												`assembly_operations.${index}.fio`,
												e ? employee?.find((el) => el.value === Number(e))?.label : '',
											);
										}}
										options={employee}
										style={{ width: '100%' }}
										className={classNames(styles['custom-select'])}
									/>
								)}
							/>
						) : (
							<Text p="6px" fontWeight="normal" textAlign="center">
								{field.fio}
							</Text>
						)}
					</Td>
					<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
						{!field.is_canceled && (!isOperationDone(field) || UserRole === UserRoles.admin) ? (
							<Controller
								name={`assembly_operations.${index}.count_out_number`}
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
									formState: { defaultValues },
								}) => (
									<Input
										backgroundColor="transparent"
										tabIndex={7}
										isInvalid={!!error}
										onChange={(e) => {
											if (field.step_id === COMPLECT_OPERATION_STEP_ID) {
												return onChange(e);
											}

											const initialOperations = cardInfo?.assembly_operations || [];
											onChange(e);
											if (
												initialOperations.length - 4 === index ||
												initialOperations[initialOperations.length - 4]?.is_canceled
											) {
												// перенос годных из "Контрольная ПСИ" в  раздел Кол-во "Сдача на СГД"
												// шт., предпоследняя кастомная операция -> первая дефолтная операция
												const sdgOut = defaultOperations[0];
												const sdgOutIdx = sortedOperationsTypes.findIndex(
													(item) => item.step_id === sdgOut.step_id && item.is_default,
												);

												setValue(
													`assembly_operations.${sdgOutIdx}.count_in_number`,
													Number(convertNumberToNumberStringWithDot(e.target.value)),
												);
											} else if (
												initialOperations.length - 3 === index ||
												initialOperations[initialOperations.length - 3].is_canceled
											) {
												const sdgIn = defaultOperations[1];
												const sdgInIdx = sortedOperationsTypes.findIndex(
													(item) => item.step_id === sdgIn.step_id && item.is_default,
												);

												// перенос годных из  "Консервации" в раздел Кол-во "Приёмка на СГД"
												// шт.,последная кастомная операция  -> вторая дефолтная операция
												setValue(
													`assembly_operations.${sdgInIdx}.count_in_number`,
													Number(convertNumberToNumberStringWithDot(e.target.value)),
												);
											} else {
												// поиск следующей неотмененной операции
												const currentOperationIndex = sortedOperationsTypes.findIndex(
													(el, nextIdx) =>
														el.step_id >= field.step_id &&
														!el.is_canceled &&
														nextIdx > index,
												);

												if (
													currentOperationIndex > -1 &&
													!defaultValues?.assembly_operations?.[currentOperationIndex]
														?.count_in_number
												) {
													// если это не последняя операция, расчет годных (штук) в этой операции переходит в грамм, штук следующей НЕ ОТМЕНЕННОЙ операции
													setValue(
														`assembly_operations.${currentOperationIndex}.count_in_number`,
														Number(convertNumberToNumberStringWithDot(e.target.value)),
													);
												}
											}

											if (!e.target.value) {
												setValue(
													// остаток от (всего штук, грамм - годны штук, грамм) переходит в брак (штук) этой операции
													`assembly_operations.${index}.wasted`,
													undefined,
												);
											} else {
												setValue(
													// остаток от (всего штук, грамм - годны штук, грамм) переходит в брак (штук) этой операции
													`assembly_operations.${index}.wasted`,
													Number(getValues(`assembly_operations.${index}.count_in_number`)) -
														Number(e.target.value),
												);
											}
										}}
										value={value}
										name={`assembly_operations.${index}.count_out_number`}
									/>
								)}
							/>
						) : (
							<Text
								p="6px"
								fontWeight="normal"
								textAlign={
									!isOperationDoneAndEmptyField(field, field.count_out_number) ? 'left' : 'center'
								}
							>
								{!isOperationDoneAndEmptyField(field, field.count_out_number)
									? field.count_out_number
									: '-'}
							</Text>
						)}
					</Td>
					<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
						{!field.is_canceled ? (
							<Controller
								name={`assembly_operations.${index}.wasted`}
								control={control}
								render={({ field: { onChange, value, name }, fieldState: { error } }) => (
									<Input
										isReadOnly={
											UserRole === UserRoles.operator || UserRole === UserRoles.senior_operator
										}
										tabIndex={7}
										isInvalid={!!error}
										onChange={onChange}
										value={value && value > 0 ? value : ''}
										name={name}
										className={field.is_canceled ? styles['field-disabled'] : ''}
									/>
								)}
							/>
						) : (
							<Text p="6px" fontWeight="normal" textAlign="left">
								{!field.wasted ? '' : field.wasted}
							</Text>
						)}
					</Td>
					<Td className={field.is_canceled ? styles['field-disabled'] : ''}></Td>
					<Td className={field.is_canceled ? styles['field-disabled'] : ''}></Td>
				</>
			);
		},
		[
			UserRole,
			cardInfo?.assembly_operations,
			control,
			defaultOperations,
			employee,
			getValues,
			isOperationDone,
			isOperationDoneAndEmptyField,
			setValue,
			sortedOperationsTypes,
		],
	);
	const renderEditModeRows = useCallback(() => {
		return (
			<>
				{customOperations.slice(assembly_products.length)?.map((field, index) => {
					const customIdx = index + assembly_products.length;

					return (
						<Tr key={`${field.id}_${customIdx}`}>
							<Td
								className={field.is_canceled ? styles['field-disabled'] : ''}
								style={{ textAlign: 'left' }}
							>
								{!field.is_canceled ? (
									<Controller
										name={`assembly_operations.${customIdx}.step_id`}
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												tabIndex={7}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												style={{ textAlign: 'left' }}
												name={`assembly_operations.${customIdx}.step_id`}
											/>
										)}
									/>
								) : (
									<Text p="6px">{field.step_id}</Text>
								)}
							</Td>
							{renderEditModeRowsContent(field, customIdx)}
						</Tr>
					);
				})}
			</>
		);
	}, [assembly_products.length, control, customOperations, renderEditModeRowsContent]);
	const renderEditModeComplectRows = useCallback(() => {
		return (
			sortedOperationsTypes?.[0] && (
				<>
					<Tr>
						<>
							<Td rowSpan={assembly_products.length} style={{ textAlign: 'left' }}>
								{'05'}
							</Td>
							{renderEditModeRowsContent(sortedOperationsTypes[0], 0)}
						</>
					</Tr>
					{sortedOperationsTypes.slice(1, assembly_products.length).map((operation, index) => (
						<Tr key={operation.id}>{renderEditModeRowsContent(operation, index + 1)}</Tr>
					))}
				</>
			)
		);
	}, [assembly_products.length, renderEditModeRowsContent, sortedOperationsTypes]);

	const newOperations = [{}, {}] as AssemblyOperationResponse[];

	return (
		<Table className={clsx(styles['operations'], styles['operations__assembly'])}>
			<Thead display="table-header-group">
				<Th rowSpan={2} style={{ textAlign: 'left', verticalAlign: 'middle' }} width="60px">
					<Flex alignItems={'center'} justifyContent={'left'}>
						№ <br />
						опер
					</Flex>
				</Th>
				<Th rowSpan={2} style={{ verticalAlign: 'middle' }} w="400px">
					Наименование операции
				</Th>
				<Th rowSpan={2} style={{ verticalAlign: 'middle' }} w="200px">
					Дата
				</Th>
				<Th rowSpan={2} style={{ verticalAlign: 'middle' }} w="200px">
					Кол-во, шт
				</Th>
				<Th rowSpan={2} style={{ verticalAlign: 'middle' }} w="225px">
					Фамилия исполнителя
				</Th>
				<Th rowSpan={2} style={{ verticalAlign: 'middle' }} w="200px">
					Годных, шт
				</Th>
				<Th rowSpan={2} style={{ verticalAlign: 'middle' }} w="150px">
					Брак, шт.
				</Th>
				<Th colSpan={2} w="400px">
					Подписи контролирующих операцию
				</Th>
				<Tr>
					<Th style={{ verticalAlign: 'middle' }} w="200px">
						Исполнитель
					</Th>
					<Th verticalAlign="middle !important" w="200px" minW={'194px'}>
						Контроль ОТК / ВП МО РФ
					</Th>
				</Tr>
			</Thead>
			<Tbody>
				<>
					{!isEditable && !isCreationMode && (
						<>
							{renderComplectRows()} {renderRows()} {emptyRows(2)}
						</>
					)}
					{isCreationMode && (
						<>
							{renderComplectRowsOnCreate()}
							{renderRowsOnCreate(sortedOperationsTypes.slice(assembly_products.length))}
							{emptyRows(2)}
						</>
					)}
					{isEditable && !isCreationMode && (
						<>
							{renderEditModeComplectRows()} {renderEditModeRows()}
						</>
					)}
				</>
				{/* Пустые строки в таблице */}
				{isEditable &&
					!isCreationMode &&
					newOperations?.map((field, index) => (
						<Tr key={customIndex + index}>
							<Td style={{ textAlign: 'left' }}>
								<Controller
									name={`assembly_operations.${customIndex + index}.step_id`}
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => {
										return (
											<Input
												tabIndex={7}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name={`assembly_operations.${customIndex + index}.step_id`}
											/>
										);
									}}
								/>
							</Td>
							<Td>
								<Controller
									name={`assembly_operations.${customIndex + index}.name`}
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<Input
											tabIndex={7}
											isInvalid={!!error}
											onChange={onChange}
											value={value}
											name={`assembly_operations.${customIndex + index}.name`}
										/>
									)}
								/>
							</Td>
							<Td>
								<Controller
									name={`assembly_operations.${customIndex + index}.date`}
									control={control}
									render={({ field: { onChange }, fieldState: { error } }) => (
										<DatePicker
											style={{ width: '100%' }}
											tabIndex={7}
											status={error ? 'error' : ''}
											onChange={onChange}
											defaultValue={
												field.date && dayjs(field.date).isValid()
													? dayjs(field.date)
													: undefined
											}
											format={'DD.MM.YYYY'}
											name={`assembly_operations.${customIndex + index}.date`}
										/>
									)}
								/>
							</Td>
							<Td>
								<Controller
									name={`assembly_operations.${customIndex + index}.count_in_number`}
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<Input
											tabIndex={7}
											onChange={onChange}
											isInvalid={!!error}
											value={value}
											name={`assembly_operations.${customIndex + index}.count_in_number`}
										/>
									)}
								/>
							</Td>
							<Td>
								<Controller
									name={`assembly_operations.${customIndex + index}.fio`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<Select
											allowClear
											tabIndex={7}
											filterOption={(input, option) =>
												(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
											}
											showSearch
											placeholder="Выберите ФИО"
											optionFilterProp="children"
											value={value}
											onChange={(e) => {
												onChange(e);
												setValue(
													`assembly_operations.${customIndex + index}.fio`,
													e ? employee?.find((el) => el.value === Number(e))?.label : '',
												);
											}}
											options={employee}
											style={{ width: '100%' }}
											className={styles['custom-select']}
										/>
									)}
								/>
							</Td>
							<Td>
								<Controller
									name={`assembly_operations.${customIndex + index}.count_out_number`}
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<Input
											tabIndex={7}
											isInvalid={!!error}
											onChange={onChange}
											value={value}
											name={`assembly_operations.${customIndex + index}.count_out_number`}
										/>
									)}
								/>
							</Td>
							<Td>
								<Controller
									name={`assembly_operations.${customIndex + index}.wasted`}
									control={control}
									render={({ field: { onChange, value, name }, fieldState: { error } }) => (
										<Input
											isReadOnly={
												UserRole === UserRoles.operator ||
												UserRole === UserRoles.senior_operator
											}
											tabIndex={7}
											isInvalid={!!error}
											onChange={onChange}
											value={value && value > 0 ? value : ''}
											name={name}
										/>
									)}
								/>
							</Td>
							<Td></Td>
							<Td></Td>
						</Tr>
					))}
			</Tbody>
		</Table>
	);
};
