import { PreformTechMapCreateRequest, PreformTechMapResponse } from '@/shared/state/api/swagger';
import { FormPreformModel } from './preform.types';
import * as yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MakeOptionalFieldsNullable } from '@/shared/core/interfaces/utility.types';
import { testForMetersFormat } from '../lib/test-for-meters-format';

export const preformTechMapCreateSchema: yup.ObjectSchema<MakeOptionalFieldsNullable<PreformTechMapCreateRequest>> = yup
	.object()
	.shape({
		d_otp: yup.string().nullable(),
		wasted: yup.number().nullable(),
		fio_pds: yup.string().required(),
		created_at: yup.string().nullable(),
		preform: yup.object().shape({
			name: yup.string().required(),
		}),
		preform_plan: yup.object().shape({
			issuance_count: yup.string().nullable().test('meters', '', testForMetersFormat),
			issuance_fio: yup.string().nullable(),
			return_count: yup.string().nullable(),
			return_fio: yup.string().nullable().test('meters', '', testForMetersFormat),
		}),
		preform_num: yup
			.object()
			.shape({
				num: yup.number().nullable(),
				symbol: yup.string().nullable(),
				gost: yup.string().nullable(),
				sortament: yup.string().nullable(),
				gost_na_sortament: yup.string().nullable(),
				number_of_melt: yup.string().nullable(),
				o_v: yup.string().nullable(),
				replace_decision: yup.string().nullable(),
				standard_consumption_type: yup
					.string()
					.oneOf(['meters', 'square_meters', 'grams', 'kg', 'old'])
					.required(),
			})
			.required(),
		preform_operations: yup
			.array()
			.of(
				yup.object().shape({
					step_id: yup.number().required(),
					name: yup.string().required(),
					fio: yup.string().nullable(),
					date: yup.string().nullable(),
					count_in_number: yup.string().nullable().test('meters', '', testForMetersFormat),
					count_out_number: yup.string().nullable().test('meters', '', testForMetersFormat),
					wasted: yup.string().nullable().test('meters', '', testForMetersFormat),
				}),
			)
			.required(),
	});

export const preformTechMapEditSchema: yup.ObjectSchema<
	MakeOptionalFieldsNullable<
		Pick<
			PreformTechMapResponse,
			'preform' | 'preform_plan' | 'preform_num' | 'preform_sgd_out' | 'preform_operations'
		>
	>
> = yup.object().shape({
	preform: yup.object().shape({
		name: yup.string().required(),
		id: yup.number().required(),
		preform_tech_map_id: yup.number().required(),
	}),
	preform_plan: yup.object().shape({
		issuance_count: yup.string().required().test('meters', '', testForMetersFormat),
		issuance_fio: yup.string().required(),
		return_count: yup.string().nullable().test('meters', '', testForMetersFormat),
		return_fio: yup.string().nullable(),
		preform_tech_map_id: yup.number().required(),
		id: yup.number().required(),
	}),
	preform_num: yup.object().shape({
		num: yup.number().nullable(),
		symbol: yup.string().nullable(),
		gost: yup.string().nullable(),
		sortament: yup.string().nullable(),
		gost_na_sortament: yup.string().nullable(),
		number_of_melt: yup.string().nullable(),
		o_v: yup.string().nullable(),
		replace_decision: yup.string().nullable(),
		date_of_manufacture: yup.string().nullable(),
		o_v_or_date_of_manufacture: yup.string().oneOf(['o_v', 'date_of_manufacture', 'empty']).nullable(),
		standard_consumption_type: yup.string().oneOf(['meters', 'square_meters', 'grams', 'kg', 'old']).required(),
	}),
	preform_sgd_out: yup.array().of(
		yup.object().shape({
			product_tech_map_receiver_id: yup.number().required(),
			product_release_receiver_id: yup.number().required(),
			date: yup.string().nullable(),
			count: yup.string().nullable().test('meters', '', testForMetersFormat),
			certificate: yup.string().nullable(),
			fio: yup.string().nullable(),
			id: yup.number().required(),
			preform_tech_map_id: yup.number().required(),
			return_count: yup.string().nullable().test('meters', '', testForMetersFormat),
			product_tech_map_receiver: yup.object().shape({
				id: yup.number().required(),
				number: yup.number().required(),
			}),
		}),
	),
	preform_operations: yup.array().of(
		yup.object().shape({
			step_id: yup.number().required(),
			name: yup.string().required(),
			fio: yup.string().nullable(),
			date: yup.string().nullable(),
			count_in_number: yup.string().nullable().test('meters', '', testForMetersFormat),
			count_out_number: yup.string().nullable().test('meters', '', testForMetersFormat),
			wasted: yup.string().nullable().test('meters', '', testForMetersFormat),
			id: yup.number().required(),
			preform_tech_map_id: yup.number().required(),
			user_id: yup.number(),
			is_canceled: yup.boolean(),
			created_at: yup.string(),
		}),
	),
});

export const usePreformFormConfig = (isCreationMode: boolean, map?: PreformTechMapResponse) => {
	const sgdOutField = (length: number) => {
		switch (length) {
			case 0:
				return new Array(3).fill({});
			default:
				return [...(map?.preform_sgd_out || []), map?.preform_sgd_out?.length ?? 0];
		}
	};

	const initialValues = useMemo<FormPreformModel>(
		() => ({
			number: map?.number,
			wasted: map?.wasted,
			created_at: map?.created_at,
			preform_num: map?.preform_num,
			preform_operations: map?.preform_operations ?? [],
			preform_plan: map?.preform_plan,
			preform: map?.preform,
			preform_sgd_out: isCreationMode ? undefined : sgdOutField(map?.preform_sgd_out?.length ?? 0),
			fio_pds: map?.fio_pds,
			accepted_at_sgd_count: map?.accepted_at_sgd_count,
			remaining_count: map?.remaining_count,
			current_details_count: map?.current_details_count,
			preform_tech_map_symbol_id: map?.preform_tech_map_symbol_id,
		}),
		[map],
	);

	const formConfig = useForm<FormPreformModel>({
		resolver: yupResolver(isCreationMode ? preformTechMapCreateSchema : preformTechMapEditSchema),
		values: initialValues,
		mode: 'all',
	});

	return formConfig;
};
